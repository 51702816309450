<template>
    <div class="nodeTemplateManage">
        <a-row
            :gutter="20"
            type="flex"
            justify="space-between"
            style="margin-left: 0; margin-right: 0"
            class="height100"
        >
            <a-col :md="6" :sm="24" style="padding-left: 0">
                <a-card class="height100">
                    <div class="tagSearch">
                        <a-form layout="inline">
                            <a-row :gutter="10" type="flex" align="middle">
                                <a-col :md="16" :sm="24">
                                    <a-form-item>
                                        <a-input
                                            @keyup.enter="getTags"
                                            v-model="queryParam.name"
                                            allow-clear
                                            placeholder="请输入模板名称"
                                        />
                                    </a-form-item>
                                </a-col>
                                <a-col :md="8" :sm="24">
                                    <span class="table-page-search-submitButtons">
                                        <a-button
                                            @click="$refs.addForm.add({ type: 'add' })"
                                            type="dashed"
                                            shape="circle"
                                            icon="plus"
                                        ></a-button>
                                    </span>
                                </a-col>
                            </a-row>
                        </a-form>
                    </div>
                    <div class="tagContainer">
                        <div class="tags">
                            <ul>
                                <li
                                    class="linContainer"
                                    :class="{
                                        active: tagActive === tagIndex,
                                    }"
                                    @click="changeActive(tag, tagIndex)"
                                    v-for="(tag, tagIndex) in tags"
                                    :key="tag.id"
                                >
                                    <div class="flex0" style="width: 100%; padding-right: 10px">
                                        <div class="flex0" style="flex: 1">
                                            <div>
                                                <img class="tagIcon" src="/images/tag.svg" alt="" />
                                            </div>
                                            <div>
                                                {{ tag.name }}
                                            </div>
                                        </div>
                                        <div class="flex0">
                                            <div
                                                style="margin-right: 10px"
                                                @click.stop="$refs.addForm.add({ type: 'edit', tag: tag })"
                                            >
                                                <a-tooltip>
                                                    <template slot="title"> 编辑 </template>
                                                    <a-icon type="form" />
                                                </a-tooltip>
                                            </div>
                                            <div @click.stop="">
                                                <a-popconfirm
                                                    title="确定删除该模板"
                                                    ok-text="确定"
                                                    cancel-text="取消"
                                                    @confirm="confirm(tag)"
                                                >
                                                    <div>
                                                        <a-tooltip>
                                                            <template slot="title"> 删除 </template>
                                                            <a-icon type="delete" />
                                                        </a-tooltip>
                                                    </div>
                                                </a-popconfirm>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </a-card>
            </a-col>
            <a-col :md="18" :sm="24" style="padding-right: 0">
                <a-card :class="['height100', tags.length > 0 ? '' : 'template-info']">
                    <template v-if="tags.length > 0">
                        <div class="nodesTitle">
                            <div style="margin-right: 10px">模板节点信息</div>
                            <div class="flex">
                                <div>
                                    <a-switch :checked="Boolean(currentTag.status)" @change="onSwitchChange" />
                                </div>
                                <div class="tip">tip: 点击完成模板的激活与关闭</div>
                            </div>
                        </div>
                        <div>
                            <a-steps direction="vertical" :current="-1" size="small">
                                <a-step v-for="el in nodes" :key="el.id">
                                    <template #title>
                                        <div style="padding-bottom: 12px">
                                            {{ el.name }}
                                        </div>
                                    </template>
                                    <template #description>
                                        <a-tag color="blue" v-for="(i, index) in el.userList" :key="index">
                                            <OpenData
                                                :type="getDataType(i)"
                                                :openid="i.deptId || i.userId"
                                                :corpid="i.corpId"
                                            ></OpenData>
                                        </a-tag>
                                    </template>
                                </a-step>
                            </a-steps>
                        </div>
                    </template>
                    <div v-else>
                        <a-empty :description="'暂无模板'" />
                    </div>
                </a-card>
            </a-col>
        </a-row>
        <addForm ref="addForm" @refresh="refresh"></addForm>
    </div>
</template>
<script>
import { delTemplate, enableTemplate, templateList } from '@/api/modular/fileStream/template'
import addForm from './components/addForm.vue'
// eslint-disable-next-line camelcase
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
import OpenData from '../documentManage/weixin/openData.vue'
export default {
    components: {
        addForm,
        OpenData,
    },
    data() {
        return {
            queryParam: {},
            tagActive: 0,
            nodes: [],
            currentTag: {},
            tags: [
                {
                    name: '模板名称',
                },
            ],
        }
    },
    created() {
        init_wx()
        this.getTemplateLiist()
    },
    methods: {
        formatNodeList(nodeList) {
            return nodeList.map((node) => ({ ...node, type: this.getDataType(node) }))
        },
        confirm({ id }) {
            delTemplate(id).then((res) => {
                if (res.code === 200) {
                    this.$message.success('删除成功')
                    this.getTemplateLiist()
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        changeActive(tag, tagIndex) {
            this.currentTag = tag
            this.nodes = tag.nodeList
            this.tagActive = tagIndex
        },
        getTemplateLiist(refresh) {
            templateList().then((res) => {
                if (res.code === 200) {
                    this.tags = [...res.data]
                    if (refresh) {
                        const index = this.tags.length - 1
                        const obj = this.tags[index]
                        this.nodes = obj.nodeList
                        this.currentTag = obj
                        this.tagActive = index
                    } else if (this.tags && this.tags.length > 0) {
                        this.nodes = this.tags[0].nodeList
                        this.currentTag = this.tags[0]
                    }
                }
            })
        },
        getDataType({ deptId }) {
            return deptId ? 'departmentName' : 'userName'
        },
        onSwitchChange(evt) {
            console.log(evt)
            enableTemplate({ id: this.currentTag.id, status: Number(evt) }).then((res) => {
                if (res.code === 200) {
                    this.$message.success(evt ? '激活成功' : '关闭成功')
                    this.currentTag.status = Number(evt)
                }
            })
        },
        refresh() {
            this.getTemplateLiist(true)
        },
        getTags({ target: { value } }) {
            if (!value) {
                this.getTemplateLiist()
            }
            this.tags = this.tags.filter((item) => item.name.includes(value))
        },
    },
}
</script>
<style lang="less" scoped>
@import url('../../common');
.flex {
    display: flex;
    align-items: center;
}
.tip {
    font-size: 14px;
    margin-left: 10px;
    color: #f00;
}
.nodeTemplateManage {
    // width: 1280px;
    width: 90%;
    margin: auto !important;
    height: calc(100vh - 120px);
    .nodesTitle {
        font-size: 18px;
        color: #000;
        font-weight: bold;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
    }
    .template-info {
        ::v-deep .ant-card-body {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
</style>
